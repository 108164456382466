import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Modal } from "react-bootstrap"

import Blank from "../../images/our-services/blank.png"

export default function VegManagement() {
  const [lgShow, setLgShow] = useState(false)
  const [lgShow1, setLgShow1] = useState(false)
  const [lgShow2, setLgShow2] = useState(false)
  const [lgShow3, setLgShow3] = useState(false)
  const [lgShow4, setLgShow4] = useState(false)
  const [lgShow5, setLgShow5] = useState(false)
  const [lgShow6, setLgShow6] = useState(false)
  const [lgShow7, setLgShow7] = useState(false)
  const [lgShow8, setLgShow8] = useState(false)
  return (
    <>
      <div id="veg-management">
        <div className="p-0">
          <Container fluid>
            <Row>
              <Col md="10" className="mx-auto">
                <h3 className="title">Vegetation Management</h3>
                <p className="main-lead">
                  Pacific Coast Land Solutions is a California Public Utility
                  Commission and six (6) State DOT “DBE” Certified
                  utility/energy/right-of-way consulting company in the U.S. We
                  enable our clients to manage their interests in a responsible
                  and self-determined way so they can proactively manage
                  vegetation across their entire rights-of-way. We stress
                  continuing education and acquiring industry certifications to
                  our team members to stay on top of and to share relevant
                  industry content with our clients around the country.
                </p>
                <h4 className="text-capitalize mt-5">
                  Utility Vegetation Management
                </h4>
                <p className="main-lead">
                  At Pacific Coast Land Solutions (PCLS), we provide integrated
                  solutions for your Utility Vegetation Management needs. Our
                  Vegetation Management Experts are highly qualified individuals
                  with the ability to assess trees and provide trimming and
                  removal prescriptions (pre-inspection). These individuals are
                  experienced in providing this type of support in California as
                  well as extremely knowledgeable with the different types of
                  trees in California. Our team has a deep understanding of all
                  California regulations in order to prescribe the work in order
                  to reduce wildfire risk. We are always up to date with all the
                  latest tools and equipment in the industry in order to provide
                  the best work possible.
                </p>
                <p className="main-lead">
                  In every step of our process, we focus on Safety and Safety
                  Training to ensure our people take this to heart.
                </p>
                <p className="main-lead">
                  Our professional team uses their vast knowledge to determine
                  areas where vegetation poses a greater risk. Our consulting
                  utility foresters (CUF) inspect and evaluate the trees and
                  brush along the right-of-way. Once complete we prepare the
                  results and recommend a course of action. We work together
                  with you to plan, provide, and direct tree clearance crews,
                  customer relations and quality control audits.
                </p>
                <p className="main-lead">
                  We are a full-service operation that provides the following
                  types of services:
                </p>
                <div className="mt-5">
                  <Row>
                    <Col lg="4">
                      <Row className="px-3">
                        <Col lg="12">
                          <ul className="w-100 justify-content-between list-wrapper">
                            <li className="d-flex align-items-center">
                              <div className="hexagon-list mr-2" />
                              {/* <a href="/" className="expect-cta"> */}
                              Safety Training
                              {/* </a> */}
                            </li>
                            <li className="d-flex align-items-center">
                              <div className="hexagon-list mr-2" />
                              {/* <a href="/" className="expect-cta"> */}
                              Pre- and post-inspection
                              {/* </a> */}
                            </li>
                            <li className="d-flex align-items-center">
                              <div className="hexagon-list mr-2" />
                              {/* <a href="/" className="expect-cta"> */}
                              Line Clearance/Tree Trimming/Tree Removal
                              {/* </a> */}
                            </li>
                            <li className="d-flex align-items-center">
                              <div className="hexagon-list mr-2" />
                              {/* <a href="/" className="expect-cta"> */}
                              Work Verification
                              {/* </a> */}
                            </li>
                            <li className="d-flex align-items-center">
                              <div className="hexagon-list mr-2" />
                              {/* <a href="/" className="expect-cta"> */}
                              Quality Control
                              {/* </a> */}
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="4">
                      <Row className="px-3">
                        <Col lg="12">
                          <ul className="w-100 justify-content-between list-wrapper">
                            <li className="d-flex align-items-center">
                              <div className="hexagon-list mr-2" />
                              {/* <a href="/" className="expect-cta"> */}
                              Forestry Consulting Services
                              {/* </a> */}
                            </li>
                            <li className="d-flex align-items-center">
                              <div className="hexagon-list mr-2" />
                              {/* <a href="/" className="expect-cta"> */}
                              Distribution, Processing & Management of
                              Merchantable Timber and Logs
                              {/* </a> */}
                            </li>
                            <li className="d-flex align-items-center">
                              <div className="hexagon-list mr-2" />
                              {/* <a href="/" className="expect-cta"> */}
                              Right-of-Way Clearing and Reclamation
                              {/* </a> */}
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="4">
                      <Row className="px-3">
                        <Col lg="12">
                          <ul className="w-100 justify-content-between list-wrapper">
                            <li className="d-flex align-items-center">
                              <div className="hexagon-list mr-2" />
                              {/* <a href="/" className="expect-cta"> */}
                              Pipeline Right-of-Way Maintenance
                              {/* </a> */}
                            </li>
                            <li className="d-flex align-items-center">
                              <div className="hexagon-list mr-2" />
                              {/* <a href="/" className="expect-cta"> */}
                              Spraying of obnoxious weeds and gross regulators
                              {/* </a> */}
                            </li>
                            <li className="d-flex align-items-center">
                              <div className="hexagon-list mr-2" />
                              {/* <a href="/" className="expect-cta"> */}
                              Emergency & Hazard Response Team
                              {/* </a> */}
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>

                <p className="main-lead">Credentials & Qualifications:</p>
                <div className="mt-5">
                  <Row>
                    <ul className="d-flex w-100 justify-content-between list-wrapper">
                      <Col lg="4">
                        <Row className="px-3">
                          <Col lg="12">
                            <ul className="w-100 justify-content-between list-wrapper">
                              <li className="d-flex align-items-center">
                                <div className="hexagon-list mr-2" />
                                {/* <a href="/" className="expect-cta"> */}
                                Certified Arborists
                                {/* </a> */}
                              </li>
                              <li className="d-flex align-items-center">
                                <div className="hexagon-list mr-2" />
                                {/* <a href="/" className="expect-cta"> */}
                                Tree Risk Assessment Qualification (TRAQ)
                                Arborists
                                {/* </a> */}
                              </li>
                              <li className="d-flex align-items-center">
                                <div className="hexagon-list mr-2" />
                                {/* <a href="/" className="expect-cta"> */}
                                Line Clearance Certified
                                {/* </a> */}
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg="4">
                        <Row className="px-3">
                          <Col lg="12">
                            <ul className="w-100 justify-content-between list-wrapper">
                              <li className="d-flex align-items-center">
                                <div className="hexagon-list mr-2" />
                                {/* <a href="/" className="expect-cta"> */}
                                Senior Consulting Foresters
                                {/* </a> */}
                              </li>
                              <li className="d-flex align-items-center">
                                <div className="hexagon-list mr-2" />
                                {/* <a href="/" className="expect-cta"> */}
                                Consulting Utility Foresters (CUF)
                                {/* </a> */}
                              </li>
                              <li className="d-flex align-items-center">
                                <div className="hexagon-list mr-2" />
                                {/* <a href="/" className="expect-cta"> */}
                                Monitors & Crew Leaders
                                {/* </a> */}
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg="4">
                        <Row className="px-3">
                          <Col lg="12">
                            <ul className="w-100 justify-content-between list-wrapper">
                              <li className="d-flex align-items-center">
                                <div className="hexagon-list mr-2" />
                                {/* <a href="/" className="expect-cta"> */}
                                Licensed Timber Operators
                                {/* </a> */}
                              </li>
                              <li className="d-flex align-items-center">
                                <div className="hexagon-list mr-2" />
                                {/* <a href="/" className="expect-cta"> */}
                                Licensed Applicators for spraying of obnoxious
                                weeds and gross regulators
                                {/* </a> */}
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </Col>
                    </ul>
                  </Row>
                </div>
              </Col>
              <Col md="10" className="mx-auto"></Col>
            </Row>
          </Container>
          <div className="services-wrapper" id="services">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-10 mx-auto">
                  <div className="d-flex flex-column align-items-center mt-5">
                    <div className="d-flex services-wrapper-1">
                      <div className="services-box">
                        <button onClick={() => setLgShow(true)}>
                          <div className="hexa-wrapper hexa-pipeline d-flex justify-content-center align-items-center">
                            <img src={Blank} alt="Safety Training" />
                            <h5 className="svc-num  text-white-50">01</h5>
                            <div className="overlay" />
                            <div className="text-center text-wrapper">
                              <p className="mb-0">Safety Training</p>
                            </div>
                          </div>
                        </button>

                        <Modal
                          className="modal-wrapper"
                          size="lg"
                          show={lgShow}
                          onHide={() => setLgShow(false)}
                          aria-labelledby="example-modal-sizes-title-lg"
                          centered
                        >
                          <Modal.Header
                            closeButton
                            style={{ borderBottom: "none", padding: "0 10px" }}
                          />
                          <Modal.Body>
                            <h3>Safety Training</h3>
                            <div className="services-description-box mt-3">
                              <p className="text-white">
                                Safety is at the forefront of our approach and
                                how we manage PCLS. Our employees receive
                                ongoing safety training in the field and are
                                held to the highest standard of safety. PCLS
                                integrates many continuing safety concepts that
                                are a part of each project, including safety
                                newsletters and weekly safety meetings to go
                                along with our daily tailgates. At PCLS, safety
                                is a part of our culture.
                              </p>
                              <p className="text-white">
                                We ensure all employees are in full compliance
                                with all regulations at every level.
                              </p>
                              <p className="text-white">
                                Our employees receive multiple levels of safety
                                training, including but not limited to:
                              </p>
                              <ul className="w-100 justify-content-between list-wrapper">
                                <div className="container">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <li className="d-flex align-items-center">
                                        <div className="hexagon-list mr-2" />
                                        {/* <a href="/" className="expect-cta"> */}
                                        Daily Safety meetings
                                        {/* </a> */}
                                      </li>
                                      <li className="d-flex align-items-center">
                                        <div className="hexagon-list mr-2" />
                                        {/* <a href="/" className="expect-cta"> */}
                                        Company Safety manual
                                        {/* </a> */}
                                      </li>
                                      <li className="d-flex align-items-center">
                                        <div className="hexagon-list mr-2" />
                                        {/* <a href="/" className="expect-cta"> */}
                                        Defensive Driving training
                                        {/* </a> */}
                                      </li>
                                    </div>
                                    <div className="col-lg-6">
                                      <li className="d-flex align-items-center">
                                        <div className="hexagon-list mr-2" />
                                        {/* <a href="/" className="expect-cta"> */}
                                        First Aid/CPR training
                                        {/* </a> */}
                                      </li>
                                      <li className="d-flex align-items-center">
                                        <div className="hexagon-list mr-2" />
                                        {/* <a href="/" className="expect-cta"> */}
                                        Tree Felling Techniques
                                        {/* </a> */}
                                      </li>
                                      <li className="d-flex align-items-center">
                                        <div className="hexagon-list mr-2" />
                                        {/* <a href="/" className="expect-cta"> */}
                                        Chain Saw Safety & Rigging
                                        {/* </a> */}
                                      </li>
                                    </div>
                                  </div>
                                </div>
                              </ul>
                              <p className="text-white">
                                Our safety training program incorporates all
                                arboricultural sides of the team including
                                Vegetation Management, Tree Trimming crews,
                                Foresters, and all other disciplines.
                              </p>
                              <p>
                                We incorporate Safety Program Managers (SPM) as
                                part of the PCLS team with the goal to assist
                                coordination between our clients and the safety
                                team. The SPM tracks the location of all crews
                                that are part of the Vegetation Management team
                                throughout the day and will conduct ongoing
                                comprehensive safety audits. Our safety audits
                                will help to ensure complete compliance,
                                diminish incidents, and promote safety
                                practices. The SPM will streamline all processes
                                and procedures to establish and prioritize a
                                safety culture that is an integral part of each
                                project and plan. Our SPM team’s goal is to work
                                closely with our clients to make this a core
                                value through effective communication as a team.
                              </p>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                      <div className="services-box">
                        <button onClick={() => setLgShow1(true)}>
                          <div className="hexa-wrapper hexa-electransmission d-flex justify-content-center align-items-center">
                            <img src={Blank} alt="Pre-Inspection" />
                            <h5 className="svc-num text-white-50">02</h5>
                            <div className="overlay" />
                            <div className="text-center text-wrapper">
                              <p className="mb-0">
                                Pre-
                                <br />
                                Inspection
                              </p>
                            </div>
                          </div>
                        </button>

                        <Modal
                          size="lg"
                          show={lgShow1}
                          onHide={() => setLgShow1(false)}
                          aria-labelledby="example-modal-sizes-title-lg"
                          centered
                          className="modal-wrapper"
                        >
                          <Modal.Header
                            closeButton
                            style={{ borderBottom: "none", padding: "0 10px" }}
                          />
                          <Modal.Body>
                            <h3>Pre-Inspection</h3>
                            <div className="services-description-box mt-3">
                              <p className="mt-3">
                                In the initial phase, our specialists perform a
                                complete pre-inspection of the Utility
                                Vegetation Management program. Our arborists
                                analyze areas for trees that pose a risk, any
                                encroachment issues and assess clearances. After
                                a thorough review, the information gathered is
                                used to create a comprehensive strategy for
                                documenting different species of vegetation,
                                precise locations and determine the rate of
                                growth, while also identifying the best method
                                of remediation.
                              </p>
                              <p className="mt-3">
                                Our professional team uses their vast knowledge
                                to determine areas where vegetation poses a
                                greater risk. Our consulting utility foresters
                                (CUF) inspect and evaluate the trees and brush
                                along the right-of-way. Once complete we prepare
                                the results and recommend a course of action. We
                                work together with you to plan, provide, and
                                direct tree clearance crews, customer relations
                                and quality control audits.
                              </p>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                      <div className="services-box">
                        <button onClick={() => setLgShow2(true)}>
                          <div className="hexa-wrapper hexa-renew d-flex justify-content-center align-items-center">
                            <img
                              src={Blank}
                              alt="Line Clearance/Tree Trimming & Removal Services"
                            />
                            <h5 className="svc-num text-white-50">03</h5>
                            <div className="overlay" />
                            <div className="text-center text-wrapper">
                              <p className="mb-0">
                                Line Clearance/
                                <br />
                                Tree Trimming & Removal Services
                              </p>
                            </div>
                          </div>
                        </button>

                        <Modal
                          className="modal-wrapper"
                          size="lg"
                          show={lgShow2}
                          onHide={() => setLgShow2(false)}
                          aria-labelledby="example-modal-sizes-title-lg"
                          centered
                        >
                          <Modal.Header
                            closeButton
                            style={{ borderBottom: "none", padding: "0 10px" }}
                          />
                          <Modal.Body>
                            <h3>
                              Line Clearance/Tree Trimming & Removal Services
                            </h3>
                            <div className="services-description-box mt-3">
                              <p className="mt-3">
                                We provide tree trimming services that includes
                                the training, safety, and management of the tree
                                trimming operations. Regardless of the size of
                                the job, PCLS is ready and capable of assisting
                                you. Our employees are highly trained on all
                                safety practices, correct pruning techniques and
                                how to deal with any and all customer issues.
                              </p>
                              <p className="mt-3">
                                Our line clearance services include:
                              </p>
                              <ul className="w-100 justify-content-between list-wrapper">
                                <div className="container">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <li className="nested-list d-block pl-2">
                                        {/* <div className="hexagon-list mr-2" /> */}
                                        Trimming and removal of vegetation along
                                        distribution and transmission lines
                                        <li className="nested-list d-block pl-2 my-3 ml-4">
                                          In addition, we teach and provide
                                          ongoing training for mechanical
                                          assisted rigging, and felling
                                          techniques, such as advanced rigging
                                          knowledge, GRCS, Hobbs systems,
                                          pulleys, and double pulleys, 3-1s, and
                                          5-1s.
                                        </li>
                                      </li>
                                      <li className="nested-list d-block pl-2">
                                        Selective herbicide applications
                                      </li>
                                      <li className="nested-list d-block pl-2">
                                        {/* <div className="hexagon-list mr-2" /> */}
                                        Mechanical and/or manual right-of-way
                                        clearing
                                        <li className="nested-list d-block pl-2 my-3 ml-4">
                                          We use the most technologically
                                          advanced heavy equipment in the tree
                                          and logging industry, including Track
                                          Chippers, Senneboggins, Grapple Saws,
                                          Grind Boxes and much more.
                                        </li>
                                      </li>
                                      <li className="nested-list d-block pl-2">
                                        Side-trimming along road rights-of-way,
                                        including aerial and ground based
                                        trimming
                                      </li>
                                    </div>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                      <div className="services-box">
                        <button onClick={() => setLgShow3(true)}>
                          <div className="hexa-wrapper hexa-pubworks d-flex justify-content-center align-items-center">
                            <img
                              src={Blank}
                              alt="Work Verification/Quality Control"
                            />
                            <h5 className="svc-num text-white-50">04</h5>
                            <div className="overlay" />
                            <div className="text-center text-wrapper">
                              <p className="mb-0">
                                Work Verification/
                                <br />
                                Quality Control
                              </p>
                            </div>
                          </div>
                        </button>

                        <Modal
                          className="modal-wrapper"
                          size="lg"
                          show={lgShow3}
                          onHide={() => setLgShow3(false)}
                          aria-labelledby="example-modal-sizes-title-lg"
                          centered
                        >
                          <Modal.Header
                            closeButton
                            style={{ borderBottom: "none", padding: "0 10px" }}
                          />
                          <Modal.Body>
                            <h3>Work Verification/Quality Control</h3>
                            <div className="services-description-box mt-3">
                              <p className="mt-3">
                                Our Arborists and Tree Risk Assessment Qualified
                                (TRAQ) Arborists audit and perform quality
                                control on the work performed immediately
                                following work completion. These specialists
                                perform thorough quality audits of the
                                vegetation management program, develop plans,
                                ensure work is in compliance and being performed
                                at a high level. Their findings are entered into
                                the project database at the end of each day.
                              </p>
                              <p className="mt-3">
                                We provide auditors to certify that each crew is
                                completing the work as instructed. After
                                trimming is completed, our Crew Leaders
                                (Monitors) inspect all work to ensure accuracy
                                and completion. Any issues that arise will be
                                delegated up the chain of command and completed
                                properly and in a timely manner. The post-audit
                                is a key technique that has long lasting effects
                                on the program and the final step in the work
                                verification process. In addition, our quality
                                control plan checks and monitors stumps from
                                tree removals to ensure these are properly
                                handled.
                              </p>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </div>
                    <div className="d-flex services-wrapper-2">
                      <div className="services-box">
                        <button onClick={() => setLgShow4(true)}>
                          <div className="hexa-wrapper hexa-geoamps d-flex justify-content-center align-items-center">
                            <img src={Blank} alt="Training & Assessment" />
                            <h5 className="svc-num text-white-50">05</h5>
                            <div className="overlay" />
                            <div className="text-center text-wrapper">
                              <p className="no-caps mb-0">
                                Training & Assessment
                              </p>
                            </div>
                          </div>
                        </button>

                        <Modal
                          className="modal-wrapper"
                          size="lg"
                          show={lgShow4}
                          onHide={() => setLgShow4(false)}
                          aria-labelledby="example-modal-sizes-title-lg"
                          centered
                        >
                          <Modal.Header
                            closeButton
                            style={{ borderBottom: "none", padding: "0 10px" }}
                          />
                          <Modal.Body>
                            <h3 className="no-caps">Training & Assessment</h3>
                            <div className="services-description-box mt-3">
                              <p className="mt-3">
                                PCLS uses Standardized Training Criteria and
                                focus on assessment to demonstrate how our
                                personnel are qualified. We take a Traceable,
                                Verifiable, Accurate and Complete approach to
                                our training. We train our employees, then we
                                test them and document this assessment to show
                                our clients. We conduct all pre-hire training
                                for our staff and can facilitate pre-hire
                                training for other consulting/service companies
                                as well if requested. This will ensure that all
                                contractors have received the same high-quality
                                training and can work together to develop a
                                complete understanding of the intended scope of
                                work.
                              </p>
                              <p className="mt-3">
                                Continuing education will be provided at
                                specified time intervals throughout each project
                                for all project personnel. We tailor our
                                training to reach each level of employee in
                                order to provide the knowledge necessary to be
                                successful, safe, and effective.
                              </p>
                              <p className="mt-3">
                                Training and education are foundations that we
                                have built our Vegetation Management services on
                                and will continue to promote consistently.
                              </p>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                      <div className="services-box">
                        <button onClick={() => setLgShow5(true)}>
                          <div className="hexa-wrapper hexa-artint d-flex justify-content-center align-items-center">
                            <img src={Blank} alt="Customer Communication" />
                            <h5 className="svc-num text-white-50">06</h5>
                            <div className="overlay" />
                            <div className="text-center text-wrapper">
                              <p className="mb-0">Customer Communication</p>
                            </div>
                          </div>
                        </button>

                        <Modal
                          size="lg"
                          show={lgShow5}
                          onHide={() => setLgShow5(false)}
                          aria-labelledby="example-modal-sizes-title-lg"
                          centered
                          className="modal-wrapper"
                        >
                          <Modal.Header
                            closeButton
                            style={{ borderBottom: "none", padding: "0 10px" }}
                          />
                          <Modal.Body>
                            <h3>Customer Communication</h3>
                            <div className="services-description-box mt-3">
                              <p className="mt-3">
                                Having outstanding customer communications is a
                                crucial aspect to every project and is a large
                                key to success. We interact with your customers
                                to help them understand the project, its
                                importance and how it benefits everyone
                                involved. For each work plan we put a priority
                                on this communication. We determine at the
                                forefront who will make contact and ensure the
                                most well-equipped individuals are available to
                                handle this important assignment. When these
                                sensitive issues occur, we have a Customer Care
                                Resolution team in place that will get involved,
                                so our clients do not have to.
                              </p>
                              <p className="mt-3">
                                We take great pride in helping our clients
                                provide clear effective communication with their
                                customers.
                              </p>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                      <div className="services-box">
                        <button onClick={() => setLgShow6(true)}>
                          <div className="hexa-wrapper hexa-artint d-flex justify-content-center align-items-center">
                            <img src={Blank} alt="Program Management" />
                            <h5 className="svc-num text-white-50">07</h5>
                            <div className="overlay" />
                            <div className="text-center text-wrapper">
                              <p className="mb-0">Program Management</p>
                            </div>
                          </div>
                        </button>

                        <Modal
                          size="lg"
                          show={lgShow6}
                          onHide={() => setLgShow6(false)}
                          aria-labelledby="example-modal-sizes-title-lg"
                          centered
                          className="modal-wrapper"
                        >
                          <Modal.Header
                            closeButton
                            style={{ borderBottom: "none", padding: "0 10px" }}
                          />
                          <Modal.Body>
                            <h3>Program Management</h3>
                            <div className="services-description-box mt-3">
                              <p className="mt-3">
                                PCLS provides complete program management for
                                each Vegetation Management plan from initiation
                                through conclusion. Full project and operational
                                management of the entire Vegetation Management
                                program, including oversight of tree crews,
                                quality control, financial metrics and many more
                                aspects.
                              </p>
                              <p className="mt-3">
                                We have experienced professionals dedicated to
                                the success of each program. It is our passion
                                to provide the comprehensive management and
                                long-term consistency that our clients deserve
                                and is a top priority for PCLS.
                              </p>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </div>
                    <div className="d-flex services-wrapper-3">
                      <div className="services-box">
                        <button onClick={() => setLgShow7(true)}>
                          <div className="hexa-wrapper hexa-artint d-flex justify-content-center align-items-center">
                            <img
                              src={Blank}
                              alt="Emergency & Hazard Response Team"
                            />
                            <h5 className="svc-num text-white-50">08</h5>
                            <div className="overlay" />
                            <div className="text-center text-wrapper">
                              <p className="mb-0">
                                Emergency & <br />
                                Hazard Response <br />
                                Team
                              </p>
                            </div>
                          </div>
                        </button>

                        <Modal
                          size="lg"
                          show={lgShow7}
                          onHide={() => setLgShow7(false)}
                          aria-labelledby="example-modal-sizes-title-lg"
                          centered
                          className="modal-wrapper"
                        >
                          <Modal.Header
                            closeButton
                            style={{ borderBottom: "none", padding: "0 10px" }}
                          />
                          <Modal.Body>
                            <h3>Emergency & Hazard Response Team</h3>
                            <div className="services-description-box mt-3">
                              <p className="mt-3">
                                PCLS can facilitate a dedicated team of
                                International Society of Arboriculture (ISA),
                                TRAQ arborists, Project Managers, OPS Managers,
                                Monitors, Crew Leads and Tree & Trimming
                                Personnel on short notice. This team can quickly
                                respond for fires or other problematic issues
                                that often arise in short time periods.
                              </p>
                              <ul className="d-flex w-100 justify-content-between list-wrapper">
                                <div className="container">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <li className="nested-list d-block pl-2">
                                        {/* <div className="hexagon-list mr-2" /> */}
                                        We specialize in arborists with
                                        backgrounds in diagnosing dead, dying,
                                        and diseased trees from every area of
                                        California especially ones affected by
                                        fire and all disasters.
                                      </li>
                                    </div>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                      <div className="services-box">
                        <button onClick={() => setLgShow8(true)}>
                          <div className="hexa-wrapper hexa-artint d-flex justify-content-center align-items-center">
                            <img src={Blank} alt="Technology Solutions" />
                            <h5 className="svc-num text-white-50">09</h5>
                            <div className="overlay" />
                            <div className="text-center text-wrapper">
                              <p className="mb-0">Technology Solutions</p>
                            </div>
                          </div>
                        </button>

                        <Modal
                          size="lg"
                          show={lgShow8}
                          onHide={() => setLgShow8(false)}
                          aria-labelledby="example-modal-sizes-title-lg"
                          centered
                          className="modal-wrapper"
                        >
                          <Modal.Header
                            closeButton
                            style={{ borderBottom: "none", padding: "0 10px" }}
                          />
                          <Modal.Body>
                            <h3>Technology Solutions</h3>
                            <div className="services-description-box mt-3">
                              <p className="mt-3">
                                Leveraging technology is crucial to creating
                                efficiencies for our clients and our employees.
                                At PCLS, we believe in using technology to
                                better our work product. This is a core value
                                from the tree crews all the way up to our
                                administration staff. We use state of the art
                                technology, (gisAMPS™) to provide real-time
                                updates, locate problem areas immediately, track
                                crew progress, manage projects, track costs and
                                increase productivity.
                              </p>
                              <p className="mt-3">
                                We utilize our own technology; however, we are
                                well versed in multiple systems, including
                                Collector, Survey 123, Krinkle, Fulcrum,
                                Explorer, KMLs, ESRI and ARCgis.
                              </p>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 text-center">
              <a
                className="submit-btn mt-5"
                href={require("../../files/Vegetation Management Overview.pdf")}
                target="_blank"
                rel="noopener noreferrer"
              >
                DOWNLOAD PDF
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
