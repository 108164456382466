import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import VegManagement from "../components/veg-management/veg-management"

export default function VegetationManagement() {
  return (
    <Layout>
      <SEO
        title="Vegetation Management"
        description="Pacific Coast Land Solutions provide right of way and title services for midstream companies, oil & gas producers, energy and utility companies, and more."
      />
      <div style={{ backgroundColor: "#000", paddingTop: "200px" }}>
        <VegManagement />
      </div>
    </Layout>
  )
}
